import React from 'react'
import { RemarkTypeEnum, TransactionTypeEnum } from 'types'
import added from 'assets/images/transactionTypes/added.svg'
import bought from 'assets/images/transactionTypes/bought.svg'
import sold from 'assets/images/transactionTypes/sold.svg'
import withdrawn from 'assets/images/transactionTypes/withdraw.svg'
import refund from 'assets/images/transactionTypes/refund.svg'
import debit from 'assets/images/transactionTypes/debit.svg'
import commission from 'assets/images/transactionTypes/commission.svg'
import { Box, Flex, Image } from '@chakra-ui/react'
import { add } from 'lodash'

const images: Record<RemarkTypeEnum | TransactionTypeEnum, string> = {
  created: added,
  added,
  bought,
  sold,
  withdrawn,
  refund,
  credit: withdrawn,
  debit,
  commission,
}

interface Props {
  transactionType: RemarkTypeEnum | TransactionTypeEnum
  variant?: 'table' | 'details'
}

export const TransactionRenderer: React.FC<Props> = ({ transactionType, variant = 'table' }) => {
  const image = images[transactionType]
  return (
    <Flex gap='0.85rem' direction={variant === 'details' ? 'column' : 'row'} alignItems='center'>
      <Image
        width={variant === 'details' ? '3.42rem' : '2.28rem'}
        height={variant === 'details' ? '3.42rem' : '2.28rem'}
        src={image}
      ></Image>
      {variant === 'table' && <Box textTransform={'capitalize'}>{transactionType}</Box>}
    </Flex>
  )
}
