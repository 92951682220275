import { Currency } from './Currency'
import { Deal } from './Deal'

export enum Currencies {
  CAD = 'CAD',
  NGN = 'NGN',
}

export type Wallet = {
  availableBalance: number
  bookBalance: number
  currency: Currency
  id: string
  status: string
}

export enum TransactionTypeEnum {
  CREDIT = 'credit',
  DEBIT = 'debit',
  COMMISSION = 'commission',
}

export enum RemarkTypeEnum {
  SOLD = 'sold',
  WITHDRAWN = 'withdrawn',
  BOUGHT = 'bought',
  ADDED = 'added',
  REFUND = 'refund',
  CREATED = 'created',
}

export enum StatusEnum {
  SUCCESSFUL = 'successful',
  FAILED = 'failed',
}

export interface DealTransaction {
  id: string
  deal: Deal
  remark: RemarkTypeEnum
  amount: number
  rate: number
  status: StatusEnum
  reference: string
  createdAt: string
  updatedAt?: string
  isUserDealCreator: boolean
  commission: number
  commType?: 'bought' | 'created'
}

export interface PayoutRequest {
  amount: number
  currencyId: string
  accountId: string
}

export interface PayoutVerifyResponse {
  commissionFee: number
  transferFee: number
  totalAmount: number
}

export interface PayoutResponse {
  message: string
  success: boolean
}

export interface WalletTransaction {
  accountName: string
  accountNumber: string
  amount: number
  bankName: string
  clearingFee: number
  createdAt: string
  id: string
  paymentReference: string
  reference: string
  sessionId: string
  status: 'successful'
  transferFee: number
  type: TransactionTypeEnum
  wallet: Wallet
}
